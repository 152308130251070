<template>
    <div>
        <div style="padding-bottom: 50px;">
            <div class="home_swipe" v-if="userInfo.id">
                <div class="qianliang-header">
                    <img :src="userInfo.headImg" style="margin-top: 10px;width:68px;height:68px;border-radius: 50%">
                    <div style="margin: 5px 0 10px;">{{userInfo.wxNickname}}</div>
                    <div style="display: flex;justify-content: space-evenly;text-align: left;font-size: 12px;">
                        <div>
                            <div style="margin-bottom: 5px;">快看ID：{{userInfo.id.substr(userInfo.id.length-8,userInfo.id.length)}}</div>
                            <div>推荐人：{{userInfo.referenceWcNickname?userInfo.referenceWcNickname:'无'}}</div>
                        </div>
                        <div>
                            <div style="margin-bottom: 5px;">注册时间：{{userInfo.createTime?userInfo.createTime.substr(0,10):'待授权'}}</div>
                            <div>推荐人微信号：{{userInfo.referenceAliasName?userInfo.referenceAliasName:'无'}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <van-cell-group inset class="qianliang-card" style="margin: 14px 0;">
                <div class="qianliang-card-header">
                    <img src="../../assets/img/qianliang/ze-balance-o@1x.png" class="qianliang-card-icon">
                    <div class="qianliang-card-title">黑钻余额：<span class="text-color" style="font-weight: bold;color: red;">{{account.canLiangpiaoNum}}</span></div>
                    <van-button size="small" class="qianliang-button qianliang-button-plain" style="margin-right: 8px;padding-top:1px;" @click="toBill">账单</van-button>
                    <van-button size="small" class="qianliang-button" style="padding-top:1px;" @click="toWithdraw">提现</van-button>
                </div>
            </van-cell-group>
        </div>
        <tabbar active="mine"></tabbar>
    </div>
</template>

<script>
import Tabbar from "./tabbar";
export default {
    name: "mine",
    components: {Tabbar},
    data(){
        return{
            userInfo:{},
            account:{
                canLiangpiaoNum: 0
            },
        }
    },
    created() {
        if(!localStorage.getItem('xToken') || !localStorage.getItem('userInfo')){
            this.$router.replace('/verify')
        }
    },
    mounted() {
        let that = this
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
        // 查询账户余额
        this.post('/member/account/queryRemainAccount').then(result => {
            if (result.respCode === "0") {
                that.account = result.data
            }
        })
    },
    methods:{
        toBill(){
            this.$router.push('/bill')
        },
        toWithdraw(){
            this.$router.push('/withdraw')
        }
    }
}
</script>

<style scoped>

</style>
